import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import FieldFormatted from "components/common/fields-v2/field-formatted";
import LoaderLinear from "components/common/loader-liner";
import ActionDialog from "components/complex/dialogs/action";
import ColorPickDialog from "components/complex/dialogs/color-picker";
import { SETTINGS_ERROR_LIST } from "constant/errors";
import { outcomeTypes, outcomeTaskTypes } from "constant/data/outcome";
import { useDidUpdate, useForm } from "hooks";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import randomColor from "randomcolor";
import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorSelectorById } from "store/error/selector";
import { opportunitiesBoardsDataSelector } from "store/opportunities/selectors";
import {
  productsServicesCategoriesDataSelector,
  productsServicesRecurrencesDataSelector
} from "store/products-services/selectors";
import validationSchema from "./validationSchema";

const SettingCreatEdit = memo(
  ({
    propData,
    isNew,
    isView,
    closePopup,
    dataSelector,
    apiKey,
    nameKey,
    addActionName,
    editActionName,
    addAction,
    editAction,
    setSelected,
    stages,
    onReorderRows,
    callUpdateRowsPositions,
    tableName,
    source
  }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, list } = useSelector(dataSelector, isEqual);
    const { list: productServiceCategories } = useSelector(productsServicesCategoriesDataSelector, isEqual);
    const boards = useSelector(apiKey === "stage" ? opportunitiesBoardsDataSelector : () => null, isEqual);
    const recurrences = useSelector(
      apiKey === "recurrence" ? productsServicesRecurrencesDataSelector : () => null,
      isEqual
    );
    const selectedAttrColor = useMemo(() => list?.find((item) => item[nameKey] === propData?.name)?.Color, [propData]);
    const resError = useSelector((store) => errorSelectorById(store, isNew ? addActionName : editActionName), isEqual);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [color, setColor] = useState(selectedAttrColor || randomColor().substring(1));
    const [isOpenedDialogForDefault, setIsOpenedDialogForDefault] = useState(false);
    const [save, setSave] = useState(true);
    const outcomeList = tableName === "task_status" ? outcomeTaskTypes : outcomeTypes;

    const fields = useMemo(() => {
      const obj = {};

      if (apiKey !== "tag") {
        obj.default = true;
      }

      switch (true) {
        case apiKey === "term": {
          obj.dueDays = true;
          break;
        }
        case apiKey === "stage": {
          // obj.position = true;
          obj.board = true;
          obj.probability = true;
          obj.color = true;
          break;
        }
        case apiKey === "sub_category": {
          obj.category_id = true;
          break;
        }

        case apiKey === "priority": {
          // obj.position = true;
          obj.color = true;
          break;
        }
        case apiKey === "recurrence": {
          obj.period = true;
        }
        // eslint-disable-next-line no-fallthrough
        default: {
          break;
        }
      }

      if (
        apiKey === "status" &&
        (tableName === "opportunity_status" || tableName === "document_status" || tableName === "task_status")
      ) {
        obj.outcome_id = true;
      }

      return obj;
    }, [apiKey]);

    const defaultValues = useMemo(() => {
      let obj;

      if (isNew) {
        obj = {
          name: "",
          ...(fields.default && { isDefault: false }),
          ...(fields.position && { position: "" }),
          ...(fields.probability && { probability: "" }),
          ...(fields.category_id && { category_id: "" }),
          ...(fields.dueDays && { dueDays: 0 }),
          ...(fields.board && { board_id: [] }),
          ...(fields.period && { period_id: [] }),
          ...(fields.outcome_id && { outcome_id: "0" })
        };
      } else {
        obj = {
          name: propData?.name,
          ...(fields.default && { isDefault: propData?.isDefault || false }),
          ...(fields.position && { position: propData?.position || "" }),
          ...(fields.probability && { probability: propData?.probability || "" }),
          ...(fields.category_id && { category_id: propData?.category_id || "" }),
          ...(fields.dueDays && { dueDays: propData?.dueDays ?? "" }),
          ...(fields.board && { board_id: boards.list.find((board) => board.name === propData?.board_id)?.code || [] }),
          ...(fields.period && {
            period_id: recurrences.list.find((period) => period.code === propData?.period_id)?.code || []
          }),
          ...(fields.outcome_id && { outcome_id: outcomeList.find((i) => i.name === propData?.outcome)?.code || "0" })
        };
      }

      return obj;
    }, [isNew, propData, fields]);
    const { FormField, handleSubmit, watch, setValue } = useForm({
      validationSchema: validationSchema(fields),
      defaultValues: { ...defaultValues },
      isLoading,
      isView
    });

    const isDefault = watch("isDefault");
    const board_id = watch("board_id");
    useDidUpdate(() => {
      if (propData && propData?.isDefault && apiKey !== "group") {
        setIsOpenedDialogForDefault(true);
        setValue("isDefault", propData.isDefault);
      }
    }, [isDefault]);

    useDidUpdate(() => {
      setSave(false);
    }, [isOpenColorPicker]);

    const handleColorChange = (newColor) => {
      if (newColor.hex === "TRANSPARENT") {
        setColor(newColor.hex);
        return;
      }
      if (newColor.hex !== "#ffffff" && newColor.hex !== "#000000") {
        setColor(newColor.hex.substring(1));
      }
    };

    function formSubmitHandler(data) {
      const finalData = {
        [apiKey]: data.name,
        ...(fields.default && { default_value: data.isDefault }),
        ...(propData?.position && { position: propData?.position }),
        ...(fields.probability && { probability: data.probability }),
        ...(fields.category_id && { category_id: data.category_id }),
        ...(fields.color && { color }),
        ...(fields.board && { board_id: data.board_id }),
        ...(fields.dueDays && { period: data.dueDays }),
        ...(fields.period && { recurrence_option_id: data.period_id }),
        ...(fields.outcome_id && { outcome_id: data.outcome_id })
      };

      if (apiKey === "location") {
        finalData[apiKey] = data;
      }
      if (isNew) {
        if (apiKey === "stage") {
          const selectedBoardName = boards.list.find((board) => board.code === board_id)?.name;
          const filteredStagedByBoard = list.filter((stage) => stage.Board === selectedBoardName);
          const greatestCurrentPosition = Math.max(...filteredStagedByBoard.map((item) => item["List Position"]), 0);
          finalData.position = greatestCurrentPosition + 1;
        }
        if (apiKey === "priority") {
          const greatestCurrentPosition = Math.max(...list.map((item) => item["List Position"]), 0);
          finalData.position = greatestCurrentPosition + 1;
        }
        dispatch(addAction(addActionName, finalData));
        if (apiKey === "location_id") {
          enqueueSnackbar(`${nameKey} saved.`, {
            variant: "success"
          });
          closePopup();
        }
        if (apiKey === "tag" && source === "formField") {
          enqueueSnackbar(`${nameKey} saved.`, {
            variant: "success"
          });
          closePopup();
        }
      } else {
        if (apiKey === "stage") {
          const selectedBoard = boards.list.find((board) => board.code === finalData.board_id);
          if (propData?.board_id !== selectedBoard.name) {
            const stageWithGreatestPosition = Math.max(
              ...list.filter((stage) => stage.Board === selectedBoard.name).map((item) => item["List Position"]),
              0
            );
            finalData.position = stageWithGreatestPosition + 1;
            const newStagesList = list.reduce((acc, item, index) => {
              if (item["List Position"] > propData?.position && propData?.board_id === item.Board) {
                acc.push({ ...item, "List Position": item["List Position"] - 1 });
              } else {
                acc.push(item);
              }
              return acc;
            }, []);
            const updatedListForBackend = stages.reduce((acc, item, index) => {
              if (item["List Position"] > propData?.position) {
                acc.push({ position: item["List Position"] - 1, opportunity_stage_id: item.opportunity_stage_id });
              }
              return acc;
            }, []);
            dispatch(onReorderRows({ idKey: "opportunity_stage_id", key: apiKey, data: newStagesList }));
            dispatch(
              callUpdateRowsPositions({
                oldPositions: list,
                tableName: "opportunity_stage",
                positions: updatedListForBackend
              })
            );
            setSelected(null);
          }
        }
        dispatch(editAction(editActionName, propData.id, finalData));
        if (apiKey === "location_id") {
          enqueueSnackbar(`${nameKey} updated.`, {
            variant: "success"
          });
          closePopup();
        }
      }
    }

    // Add / Edit success handler
    useDidUpdate(() => {
      function onSuccess() {
        enqueueSnackbar(isNew ? `${nameKey} saved.` : `${nameKey} updated.`, {
          variant: "success"
        });
        closePopup();
      }

      if (!isLoading) {
        if (resError) {
          if (!resError.isVisible) {
            onSuccess();
          }
        } else {
          onSuccess();
        }
      }
    }, [isLoading]);

    return (
      <>
        <DialogTitle id={`${apiKey}-dialog-title`}>{isNew ? `Create ${nameKey}` : `Edit ${nameKey}`}</DialogTitle>
        <DialogContent onChange={() => setSave(false)}>
          <form id={`${apiKey}-form`}>
            <Grid container columnSpacing={2}>
              {fields.board && (
                <Grid item xs={12}>
                  {FormField({
                    name: "board_id",
                    label: "Board",
                    type: "select",
                    options: boards.list
                  })}
                </Grid>
              )}
              <Grid item xs={nameKey === "Stage" || nameKey === "Recurrence" ? 12 : fields.default ? 8 : 12}>
                {FormField({
                  name: "name",
                  label: apiKey === "recurrence" ? "Description" : "name"
                })}
              </Grid>
              {fields.dueDays && (
                <Grid item xs={4}>
                  {FormField({
                    label: "Due Days",
                    name: "dueDays"
                  })}
                </Grid>
              )}
              {fields.default && nameKey !== "Stage" && (
                <Grid item xs={nameKey === "Priority" ? 4 : 8}>
                  {FormField({
                    name: "isDefault",
                    label: "Default",
                    type: "checkbox"
                  })}
                </Grid>
              )}
              {fields.position && (
                <Grid item xs={4}>
                  {FormField({
                    name: "position",
                    label: "Position"
                  })}
                </Grid>
              )}
              {fields.period && (
                <Grid item xs={12} sm={12}>
                  {FormField({
                    name: "period_id",
                    label: "Period",
                    type: "select",
                    options: recurrences.list
                  })}
                </Grid>
              )}
              {fields.probability && (
                <Grid item xs={nameKey === "Stage" ? 3.5 : 8}>
                  {FormField({
                    name: "probability",
                    InputProps: {
                      inputComponent: FieldFormatted
                    },
                    inputProps: {
                      maskedType: "percent"
                    }
                  })}
                </Grid>
              )}
              {fields.color && (
                <Grid item xs={nameKey === "Stage" ? 5.5 : 4}>
                  <Button
                    sx={{
                      ...(nameKey === "Stage" && { paddingTop: "10px" })
                    }}
                    className="color-picker-button">
                    <Box
                      onClick={() => setIsOpenColorPicker(true)}
                      style={{
                        backgroundColor: color === "TRANSPARENT" ? color : `#${color}`
                      }}
                    />
                    Select color
                  </Button>
                  <ColorPickDialog
                    isOpen={isOpenColorPicker}
                    color={color}
                    handleChange={handleColorChange}
                    closePopup={() => setIsOpenColorPicker(false)}
                  />
                </Grid>
              )}
              {fields.default && nameKey === "Stage" && (
                <Grid item xs={3}>
                  {FormField({
                    name: "isDefault",
                    label: "Default",
                    type: "checkbox"
                  })}
                </Grid>
              )}
              {fields.category_id && (
                <Grid item xs={12}>
                  {FormField({
                    name: "category_id",
                    label: "Category",
                    type: "select",
                    options: productServiceCategories
                  })}
                </Grid>
              )}
              {fields.outcome_id && (
                <Grid item xs={12}>
                  {FormField({
                    name: "outcome_id",
                    label: "Outcome",
                    type: "radio",
                    options: outcomeList
                  })}
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          {!isView && (
            <Button
              autoFocus
              disabled={isLoading || save}
              form={`${apiKey}-form`}
              onClick={handleSubmit(formSubmitHandler)}>
              Save
            </Button>
          )}
          <Button type="button" disabled={isLoading} onClick={closePopup}>
            Cancel
          </Button>
        </DialogActions>
        <ActionDialog
          isOpen={isOpenedDialogForDefault}
          closePopup={() => setIsOpenedDialogForDefault(false)}
          cancelButtonText="OK">
          {SETTINGS_ERROR_LIST.CHANGE_DEFAULT_VALUE}
        </ActionDialog>

        {isLoading && <LoaderLinear />}
      </>
    );
  }
);

SettingCreatEdit.propTypes = {
  apiKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  dataSelector: PropTypes.func,
  addActionName: PropTypes.string,
  editActionName: PropTypes.string,
  isNew: PropTypes.bool,
  isView: PropTypes.bool,
  propData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    name: PropTypes.string.isRequired,
    isDefault: PropTypes.bool,
    position: PropTypes.number,
    probability: PropTypes.string
  }),
  closePopup: PropTypes.func.isRequired,
  addAction: PropTypes.func,
  editAction: PropTypes.func
};

SettingCreatEdit.defaultProps = {
  isNew: false,
  isView: false,
  propData: undefined,
  editActionName: undefined,
  editAction: undefined
};

export default SettingCreatEdit;
