import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "store/error/slice";
import {
  TASK_PRIORITIES_CALL,
  TASK_PRIORITY_ADD_CALL,
  TASK_PRIORITY_EDIT_CALL,
  TASK_PRIORITY_DELETE_CALL,
  TASK_TAGS_CALL,
  TASK_TAG_ADD_CALL,
  TASK_TAG_EDIT_CALL,
  TASK_TAG_DELETE_CALL,
  TASK_TYPES_CALL,
  TASK_TYPE_ADD_CALL,
  TASK_TYPE_EDIT_CALL,
  TASK_TYPE_DELETE_CALL,
  TASK_PRIORITY_ROWS_POSITION_UPDATE,
  TASK_STATUSES_CALL,
  TASK_STATUS_ADD_CALL,
  TASK_STATUS_EDIT_CALL,
  TASK_STATUS_DELETE_CALL
} from "./sagas-actions";
import {
  onCallTaskSettings,
  onSuccessTaskSettingDelete,
  onSuccessTaskSettingEdit,
  onSuccessTaskSettingAdd,
  switchTaskSettingLoading,
  onFailedTaskSettings,
  onSuccessTaskSettings,
  onFailedReorderStageRowsSetting
} from "./slice";
import { onSuccessTaskAttributeAdd } from "../../task/slice";

const taskSettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallTaskSettings({
        key
      })
    );
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/task-settings/${url}`);

      yield put(
        onSuccessTaskSettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedTaskSettings({
          key
        })
      );
    }
  };

const taskSettingAddCallGenerator = (key, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchTaskSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemData }
      } = action;
      const { data } = yield call(axiosInstance.post, `/tenantUser/task-settings/${url}`, itemData);
      yield put(
        onSuccessTaskSettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessTaskAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchTaskSettingLoading({
        key,
        status: false
      })
    );
  };

const taskSettingEditCallGenerator = (key, idKey, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchTaskSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `/tenantUser/task-settings/${url}/${itemId}`, itemData);
      yield put(
        onSuccessTaskSettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchTaskSettingLoading({
        key,
        status: false
      })
    );
  };

const taskSettingDeleteCallGenerator = (key, idKey, url) =>
  function* gen(action) {
    yield put(
      switchTaskSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `/tenantUser/task-settings/generic_delete/${itemId}/${url}`);
      yield put(
        onSuccessTaskSettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchTaskSettingLoading({
        key,
        status: false
      })
    );
  };

function* updatePriorityRowsPositionsGenerator(action) {
  const { positions, tableName, oldPositions } = action.payload;
  try {
    yield call(axiosInstance.put, `/tenantUser/changePosition/${tableName}`, positions);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedReorderStageRowsSetting({ key: "priority", data: oldPositions }));
  }
}

export default function* taskSettingsSaga() {
  yield takeEvery(TASK_PRIORITIES_CALL, taskSettingsCallGenerator("priority", "task_priority_list"));
  yield takeEvery(
    TASK_PRIORITY_ADD_CALL,
    taskSettingAddCallGenerator("priority", "task_priority", "createdTaskPriority")
  );
  yield takeEvery(
    TASK_PRIORITY_EDIT_CALL,
    taskSettingEditCallGenerator("priority", "task_priority_id", "task_priority", "updatedTaskPriority")
  );
  yield takeEvery(
    TASK_PRIORITY_DELETE_CALL,
    taskSettingDeleteCallGenerator("priority", "task_priority_id", "task_priority")
  );
  yield takeEvery(TASK_PRIORITY_ROWS_POSITION_UPDATE, updatePriorityRowsPositionsGenerator);

  // Tag
  yield takeEvery(TASK_TAGS_CALL, taskSettingsCallGenerator("tag", "task_tag_definition_list"));
  yield takeEvery(TASK_TAG_ADD_CALL, taskSettingAddCallGenerator("tag", "task_tag", "createdTaskTag"));
  yield takeEvery(
    TASK_TAG_EDIT_CALL,
    taskSettingEditCallGenerator("tag", "task_tag_definition_id", "task_tag", "updatedTaskTag")
  );
  yield takeEvery(TASK_TAG_DELETE_CALL, taskSettingDeleteCallGenerator("tag", "task_tag_definition_id", "task_tag"));

  // Type
  yield takeEvery(TASK_TYPES_CALL, taskSettingsCallGenerator("type", "task_type_list"));
  yield takeEvery(TASK_TYPE_ADD_CALL, taskSettingAddCallGenerator("type", "task_type", "createdTaskType"));
  yield takeEvery(
    TASK_TYPE_EDIT_CALL,
    taskSettingEditCallGenerator("type", "task_type_id", "task_type", "updatedTaskType")
  );
  yield takeEvery(TASK_TYPE_DELETE_CALL, taskSettingDeleteCallGenerator("type", "task_type_id", "task_type"));
  yield takeEvery(TASK_PRIORITY_ROWS_POSITION_UPDATE, updatePriorityRowsPositionsGenerator);

  // Status
  yield takeEvery(TASK_STATUSES_CALL, taskSettingsCallGenerator("status", "task_status_list"));
  yield takeEvery(TASK_STATUS_ADD_CALL, taskSettingAddCallGenerator("status", "task_status", "createdTaskStatus"));
  yield takeEvery(
    TASK_STATUS_EDIT_CALL,
    taskSettingEditCallGenerator("status", "task_status_id", "task_status", "updatedTaskStatus")
  );
  yield takeEvery(TASK_STATUS_DELETE_CALL, taskSettingDeleteCallGenerator("status", "task_status_id", "task_status"));
}
