import { createPromiseAction } from "@adobe/redux-saga-promise";

export const DOCUMENT_CALL_HEAD = "DOCUMENT_CALL_HEAD";
export const DOCUMENT_CALL_HEAD_WITH_CHANGED_FIELDS = "DOCUMENT_CALL_HEAD_WITH_CHANGED_FIELDS";
export const UPDATE_DOCUMENT_ITEM_FROM_TEMPLATE = "UPDATE_DOCUMENT_ITEM_FROM_TEMPLATE";
export const DOCUMENT_CALL_ITEM = "DOCUMENT_CALL_ITEM";
export const DOCUMENTS_CALL_PROMISE = "DOCUMENTS_CALL_PROMISE";
export const DOCUMENTS_CALL = "DOCUMENTS_CALL";
export const DOCUMENT_ADD_CALL = "DOCUMENT_ADD_CALL";
export const DOCUMENT_ADD_CALL_PROMISE = "DOCUMENT_ADD_CALL_PROMISE";
export const DOCUMENT_EDIT_CALL_PROMISE = "DOCUMENT_EDIT_CALL_PROMISE";
export const DOCUMENTS_DELETE_CALL_PROMISE = "DOCUMENTS_DELETE_CALL_PROMISE";
export const DOCUMENT_EDIT_CALL = "DOCUMENT_EDIT_CALL";
export const DOCUMENTS_DELETE_CALL = "DOCUMENTS_DELETE_CALL";
export const DOCUMENTS_RESTORE_CALL = "DOCUMENTS_RESTORE_CALL";
export const PRODUCTS_SERVICE_LOCATION_ADD_CALL = "PRODUCTS_SERVICE_LOCATION_ADD_CALL";
export const DOCUMENT_NOTES_CALL = "DOCUMENT_NOTES_CALL";
export const DOCUMENT_TASKS_CALL = "DOCUMENT_TASKS_CALL";
export const DOCUMENT_REVISIONS_CALL = "DOCUMENT_REVISIONS_CALL";
export const DOCUMENT_APPROVE_CALL = "DOCUMENT_APPROVE_CALL";
export const DOCUMENT_REQUEST_APPROVAL_CALL = "DOCUMENT_REQUEST_APPROVAL_CALL";
export const DOCUMENT_ADD_TEMPLATE_PROMISE = "DOCUMENT_ADD_TEMPLATE_PROMISE";

export const CONTACTS_SELECT_SEARCH = "CONTACTS_SELECT_SEARCH";
export const COMPANIES_SELECT_SEARCH = "COMPANIES_SELECT_SEARCH";

export const ADD_PRODUCT_FROM_PDF = "ADD_PRODUCT_FROM_PDF";

export const CREATE_TEMPLATE_FROM_DOCUMENT = "CREATE_TEMPLATE_FROM_DOCUMENT";

export const CHANGE_ACTIVE_STATUS = "CHANGE_ACTIVE_STATUS";

export const CALL_SELECTED_SIGNATURES = "CALL_SELECTED_SIGNATURES";
export const CALL_REMOVE_SELECTED_SIGNATURE = "CALL_REMOVE_SELECTED_SIGNATURE";

export const DOCUMENT_ITEM_ADD_CALL = "DOCUMENT_ITEM_ADD_CALL";
export const DOCUMENT_ITEM_ADD_PROMISE_CALL = "DOCUMENT_ITEM_ADD_PROMISE_CALL";
export const DOCUMENT_ITEM_EDIT_CALL = "DOCUMENT_ITEM_EDIT_CALL";
export const DOCUMENT_SHIPPING_EDIT_CALL = "DOCUMENT_SHIPPING_EDIT_CALL";

// export const DOCUMENT_PRODUCT_SERVICES_CALL = 'DOCUMENT_PRODUCT_SERVICES_CALL';
export const DOCUMENT_PRODUCT_SERVICE_ADD_CALL = "DOCUMENT_PRODUCT_SERVICE_ADD_CALL";
export const DOCUMENT_PRODUCT_SERVICE_EDIT_CALL = "DOCUMENT_PRODUCT_SERVICE_EDIT_CALL";
export const DOCUMENT_EXTERNAL_PRODUCT_SERVICE_ITEMS_EDIT_CALL = "DOCUMENT_EXTERNAL_PRODUCT_SERVICE_ITEMS_EDIT_CALL";
export const DOCUMENT_PRODUCT_SERVICES_DELETE_CALL = "DOCUMENT_PRODUCT_SERVICES_DELETE_CALL";
export const PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL = "PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL";

export const DOCUMENTS_CALL_STATUSES = "DOCUMENTS_CALL_STATUSES";
export const DOCUMENTS_CALL_TYPES = "DOCUMENTS_CALL_TYPES";
export const DOCUMENTS_CALL_ADJUSTMENTS = "DOCUMENTS_CALL_ADJUSTMENTS";
export const DOCUMENTS_CALL_DESTINATION = "DOCUMENTS_CALL_DESTINATION";
export const DOCUMENTS_CALL_TEMPLATES = "DOCUMENTS_CALL_TEMPLATES";

export const DOCUMENT_STATUSES_CALL = "DOCUMENT_STATUSES_CALL";
export const DOCUMENT_STATUS_ADD_CALL = "DOCUMENT_STATUS_ADD_CALL";
export const DOCUMENT_STATUS_EDIT_CALL = "DOCUMENT_STATUS_EDIT_CALL";
export const DOCUMENT_STATUS_DELETE_CALL = "DOCUMENT_STATUS_DELETE_CALL";

export const DOCUMENT_ALL_ATTRIBUTES_CALL = "DOCUMENT_ALL_ATTRIBUTES_CALL";

export const DOCUMENT_TYPES_CALL = "DOCUMENT_TYPES_CALL";
export const DOCUMENT_TYPE_ADD_CALL = "DOCUMENT_TYPE_ADD_CALL";
export const DOCUMENT_TYPE_EDIT_CALL = "DOCUMENT_TYPE_EDIT_CALL";
export const DOCUMENT_TYPE_DELETE_CALL = "DOCUMENT_TYPE_DELETE_CALL";

export const DOCUMENT_PAYMENT_TERM_CALL = "DOCUMENT_PAYMENT_TERM_CALL";
export const DOCUMENT_PAYMENT_TERM_ADD_CALL = "DOCUMENT_PAYMENT_TERM_ADD_CALL";
export const DOCUMENT_PAYMENT_TERM_EDIT_CALL = "DOCUMENT_PAYMENT_TERM_EDIT_CALL";
export const DOCUMENT_PAYMENT_TERM_DELETE_CALL = "DOCUMENT_PAYMENT_TERM_DELETE_CALL";

export const DOCUMENTS_CHANGE_STATUSES = "DOCUMENTS_CHANGE_STATUSES";
export const DOCUMENTS_CHANGE_TYPES = "DOCUMENTS_CHANGE_TYPES";

export const DOCUMENT_SEND_EMAIL_PROMISE = "DOCUMENT_SEND_EMAIL_PROMISE";

export const DOCUMENTS_TASK_CALL = "DOCUMENTS_TASK_CALL";

export const DOCUMENT_TENANT_SETTINGS_CALL = "DOCUMENT_TENANT_SETTINGS_CALL";
export const DOCUMENT_TENANT_SETTINGS_EDIT_CALL = "DOCUMENT_TENANT_SETTINGS_EDIT_CALL";

export const DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL = "DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL";

export const DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL_PROMISE = "DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL_PROMISE";

export const DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY = "DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY";

export const DOCUMENT_OUTSOURCE_PRODUCT_SERVICES_CALL_PROMISE = "DOCUMENT_OUTSOURCE_PRODUCT_SERVICES_CALL";
export const DOCUMENT_PRODUCT_SERVICES_EDIT_TAXABLE_CALL = "DOCUMENT_PRODUCT_SERVICES_EDIT_TAXABLE_CALL";
export const DOCUMENT_PRODUCT_SERVICES_ITEM_IDS_EDIT = "DOCUMENT_PRODUCT_SERVICES_ITEM_IDS_EDIT";
export const DOCUMENT_FROM_LINK_ADD_CALL = "DOCUMENT_FROM_LINK_ADD_CALL";
export const PRODUCTS_SERVICE_LINK_EDIT_CALL = "PRODUCTS_SERVICE_LINK_EDIT_CALL";
export const CALL_RESET_SIGNATURES = "CALL_RESET_SIGNATURES";
export const LAYOUT_CHANGED = "LAYOUT_CHANGED";

export const GET_MERGE_FIELDS = "GET_MERGE_FIELDS";

export const callDocumentsPromise = createPromiseAction(DOCUMENTS_CALL_PROMISE);
export const documentSendEmailPromise = createPromiseAction(DOCUMENT_SEND_EMAIL_PROMISE);
export const documentOutsourceProductServicePromise = createPromiseAction(
  DOCUMENT_OUTSOURCE_PRODUCT_SERVICES_CALL_PROMISE
);
// export const callDocumentAddPromise = createPromiseAction(DOCUMENT_ADD_CALL_PROMISE);
export const documentAddPromise = createPromiseAction(DOCUMENT_ADD_CALL_PROMISE);
export const documentEditPromise = createPromiseAction(DOCUMENT_EDIT_CALL_PROMISE);
export const documentDeletePromise = createPromiseAction(DOCUMENTS_DELETE_CALL_PROMISE);
export const documentItemAddPromise = createPromiseAction(DOCUMENT_ITEM_ADD_PROMISE_CALL);
export const documentProductServiceSearchPromise = createPromiseAction(DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL_PROMISE);
export const documentChangeActiveStatusPromise = createPromiseAction(CHANGE_ACTIVE_STATUS);
export const documentAddTemplatePromise = createPromiseAction(DOCUMENT_ADD_TEMPLATE_PROMISE);

export function getContactsSelectSearch() {
  return {
    type: CONTACTS_SELECT_SEARCH
  };
}

export function getCompaniesSelectSearch() {
  return {
    type: COMPANIES_SELECT_SEARCH
  };
}

export function editDocumentShipping(documentId, shippingRate, shippingTax) {
  return { type: DOCUMENT_SHIPPING_EDIT_CALL, payload: { documentId, shippingRate, shippingTax } };
}

export function getMergeFields(data, resolve, reject) {
  return { type: GET_MERGE_FIELDS, payload: { data, resolve, reject } };
}

export function createTemplateFromDocument(documentId, templateName, messageFunction) {
  return { type: CREATE_TEMPLATE_FROM_DOCUMENT, payload: { documentId, templateName, messageFunction } };
}

export function callDocuments(paginationData, tenantUserId, withoutLoading) {
  return { type: DOCUMENTS_CALL, payload: { paginationData, tenantUserId, withoutLoading } };
}

export function callDocumentPaymentTerm() {
  return { type: DOCUMENT_PAYMENT_TERM_CALL };
}

export function addProductFromPdf(file, documentId, productServiceId, newPs) {
  return { type: ADD_PRODUCT_FROM_PDF, payload: { file, documentId, productServiceId, newPs } };
}

export function callDocumentApprove(documentId, data, messageFunction) {
  return { type: DOCUMENT_APPROVE_CALL, payload: { documentId, data, messageFunction } };
}

export function callDocumentRequestApproval(documentId, messageFunction) {
  return { type: DOCUMENT_REQUEST_APPROVAL_CALL, payload: { documentId, messageFunction } };
}

export function callDocumentProductServicesSearch(search_value, lookupPlace, isShowInStockItemsOnly) {
  return {
    type: DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL,
    payload: { search_value, lookupPlace, isShowInStockItemsOnly }
  };
}

export function callCheckInventoryForProductService(partNumber, upc) {
  return { type: DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY, payload: { part_number: partNumber, upc } };
}

export function addLocation(actionName, locationName) {
  return { type: actionName, payload: { locationName } };
}

export function editProductServicesIsTaxable(taxableInfo) {
  return { type: DOCUMENT_PRODUCT_SERVICES_EDIT_TAXABLE_CALL, payload: { taxableInfo } };
}

export function callDocumentsForTask() {
  return { type: DOCUMENTS_TASK_CALL };
}

// DOCUMENT HEAD

export function callDocumentHead(documentId, withoutLoading = false, reset) {
  return { type: DOCUMENT_CALL_HEAD, payload: { documentId, withoutLoading, reset } };
}

export function callDocumentHeadWithChangedFields(documentId, fields) {
  return { type: DOCUMENT_CALL_HEAD_WITH_CHANGED_FIELDS, payload: { documentId, fields } };
}

export function updateDocumentItemFromTemplate(templateId) {
  return { type: UPDATE_DOCUMENT_ITEM_FROM_TEMPLATE, payload: { templateId } };
}

export function callDocumentItem(documentId) {
  return { type: DOCUMENT_CALL_ITEM, payload: { documentId } };
}

export function callDocumentTenantSettings() {
  return { type: DOCUMENT_TENANT_SETTINGS_CALL };
}

export function callEditDocumentTenantSettings(data, messageFunction) {
  return { type: DOCUMENT_TENANT_SETTINGS_EDIT_CALL, payload: { tenantData: data, messageFunction } };
}

export function callDocumentNotes(entityId, module, isSocketUpdate, documentNumber, selectedTypes, withoutLoading) {
  return { type: DOCUMENT_NOTES_CALL, payload: { entityId, module, isSocketUpdate, documentNumber, selectedTypes, withoutLoading } };
}

export function callDocumentTasks(documentId, isSocketUpdate) {
  return { type: DOCUMENT_TASKS_CALL, payload: { documentId, isSocketUpdate } };
}

export function callDocumentRevisions(documentId, isSocketUpdate) {
  return { type: DOCUMENT_REVISIONS_CALL, payload: { documentId, isSocketUpdate } };
}

export function addDocument(documentData) {
  return { type: DOCUMENT_ADD_CALL, payload: { documentData } };
}

export function editDocument(documentId, documentData, oppName) {
  return { type: DOCUMENT_EDIT_CALL, payload: { documentId, documentData, oppName } };
}

export function deleteDocuments(documentIds, messageFunction) {
  return { type: DOCUMENTS_DELETE_CALL, payload: { documentIds, messageFunction } };
}

// DOCUMENT ITEM

export function addDocumentItem(documentItemData) {
  return { type: DOCUMENT_ITEM_ADD_CALL, payload: { documentItemData } };
}

export function editDocumentItem(documentItemId, documentItemData) {
  return { type: DOCUMENT_ITEM_EDIT_CALL, payload: { documentItemId, documentItemData } };
}

export function editProductServiceItemIds(data) {
  return { type: DOCUMENT_PRODUCT_SERVICES_ITEM_IDS_EDIT, payload: data };
}

export function editExternalProductServiceItems(documentItemId, key, documentItemData) {
  return {
    type: DOCUMENT_EXTERNAL_PRODUCT_SERVICE_ITEMS_EDIT_CALL,
    payload: { documentItemId, key, documentItemData }
  };
}

// export function callDocumentProductServices(documentId) {
//     return { type: DOCUMENT_PRODUCT_SERVICES_CALL, payload: { documentId } };
// }

export function addDocumentProductsService(productServiceData, newPhotoFile) {
  return { type: DOCUMENT_PRODUCT_SERVICE_ADD_CALL, payload: { productServiceData, newPhotoFile } };
}

export function editDocumentProductsServices(productServiceData, location, itemToDelete, messageFunction) {
  return {
    type: DOCUMENT_PRODUCT_SERVICE_EDIT_CALL,
    payload: { productServiceData, location, itemToDelete, messageFunction }
  };
}

export function deleteDocumentProductsServices(productServiceIds) {
  return { type: DOCUMENT_PRODUCT_SERVICES_DELETE_CALL, payload: { productServiceIds } };
}

export function documentProductsServiceEditCallGenerator(psPayload, messageFunction) {
  return { type: PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL, payload: { psPayload, messageFunction } };
}

// SETTINGS

export const callDocumentSettings = (actionName) => () => ({ type: actionName });

export function callAddDocumentSetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditDocumentSetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteDocumentSetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

// ATTRIBUTES

export function callDocumentAttributes(type) {
  return { type };
}

export function callAllDocumentAttributes() {
  return { type: DOCUMENT_ALL_ATTRIBUTES_CALL };
}

export function callDocumentsChangeAttributes(type, data) {
  return { type, payload: { data } };
}

export function getDocumentFromLink(data) {
  return { type: DOCUMENT_FROM_LINK_ADD_CALL, payload: data };
}

export function onEditProductServiceInLink(data) {
  return { type: PRODUCTS_SERVICE_LINK_EDIT_CALL, payload: data };
}

export function callSelectedSignatures(payload) {
  return { type: CALL_SELECTED_SIGNATURES, payload };
}

export function callResetSelectedSignatures() {
  return { type: CALL_RESET_SIGNATURES };
}

export function callRemoveSelectedSignature(payload) {
  return { type: CALL_REMOVE_SELECTED_SIGNATURE, payload };
}

export function callLayoutChange(changed) {
  return { type: LAYOUT_CHANGED, payload: changed };
}
