import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";
import { cloneDeep } from "lodash";

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    isFetched: false,
    isLoading: false,
    columnNames: [],
    companies: [],
    allCompanies: [],
    fetchedPages: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
    cardLabels: {},
    cardStructure: {},

    // single company
    company: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },

    companyContacts: {
      isFetched: false,
      data: {},
      columnNames: []
    },

    orgRep: {
      isFetched: false,
      data: {},
      columnNames: [],
      isLoading: false
    },

    companyNotes: {
      isFetched: false,
      data: {},
      columnNames: [],
      isLoading: false
    },

    companyTasks: {
      isFetched: false,
      isLoading: false,
      data: {},
      columnNames: []
    },

    status: {
      isFetched: false,
      list: []
    },
    type: {
      isFetched: false,
      list: []
    },
    industryClassification: {
      isFetched: false,
      list: []
    },
    group: {
      isFetched: false,
      list: []
    },
    customField: {
      isFetched: false,
      list: []
    },
    socialMedias: {
      isFetched: false,
      list: []
    },
    activeTenantUsers: {
      isFetched: false,
      list: []
    },
    companyDraft: {},

    companyDocuments: {
      isFetched: false,
      data: {},
      columnNames: []
    }
  },
  reducers: {
    // all companies
    onCallCompanies: (state, { payload }) => ({
      ...state,
      pagination: payload || state.pagination
    }),
    onSuccessCompanies: (state, { payload }) => {
      state.isFetched = true;
      state.isLoading = false;
      state.count = parseInt(payload?.total_count, 10);
      const currentPage =  (state?.pagination?.skip / state?.pagination.limit) + 1;
      const currentPages = cloneDeep(state.fetchedPages);


      if (!currentPages?.find(item => item === currentPage)) {
        state.fetchedPages = [...currentPages, currentPage];
        state.allCompanies = state.allCompanies.concat(payload.tableData);
      }

      if (payload.uiViewType === "card") {
        state.companies = payload.shouldClear ? payload.tableData : state.companies.concat(payload.tableData);
        state.pagination.skip = payload.shouldClear
          ? payload.paginationData.limit
          : payload.paginationData.skip + payload.paginationData.limit;
        state.cardLabels = payload.structure.card_label;
        state.cardStructure = payload.structure.card_structure;
      } else {
        state.columnNames = payload.columnNames;
        state.companies = payload.tableData;
      }
    },
    onFailedCompanies: (state) => {
      state.isFetched = true;
      state.isLoading = false;
      state.companies = [];
      state.columnNames = [];
      state.count = 0;
    },

    // single company
    switchCompanyLoading: (state, { payload }) => {
      state.company.isLoading = payload !== undefined ? payload : !state.company.isLoading;
    },

    onSuccessCompany: (state, { payload }) => {
      state.company.isFetched = true;
      state.company.data = {
        ...payload,
        ...(payload?.logo && {
          logo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/companyLogo/${
            payload.company_id
          }/${payload.logo}`
        })
      };
    },
    onFailedCompany: (state) => {
      state.company.isFetched = true;
      state.company.data = undefined;
    },
    resetCompany: (state) => {
      state.company.isFetched = false;
      state.company.data = undefined;
    },

    onCallCompanyContacts: (state) => {
      state.companyContacts.isFetched = false;
      state.companyContacts.columnNames = [];
      state.companyContacts.data = [];
    },
    onSuccessCompanyContacts: (state, { payload }) => {
      state.companyContacts.isFetched = true;
      if (payload && payload?.contacts_list && payload?.columnNames) {
        state.companyContacts.data = payload.contacts_list;
        state.companyContacts.columnNames = payload.columnNames.filter((cN) => cN.column_name !== "contact_id");
      }
    },
    onFailedCompanyContacts: (state) => {
      state.companyContacts.isFetched = true;
      state.companyContacts.data = [];
      state.companyContacts.columnNames = [];
    },
    onCallCompanyNotes: (state, { payload }) => {
      if (!payload) {
        state.companyNotes.isFetched = false;
      }
      state.companyNotes.columnNames = [];
      state.companyNotes.data = [];
      state.companyNotes.isLoading = true;
    },
    onSuccessCompanyNotes: (state, { payload }) => {
      state.companyNotes.isFetched = true;
      state.companyNotes.data = payload.tableData;
      state.companyNotes.columnNames = payload.columnNames;
      state.companyNotes.isLoading = false;
    },
    onAddNewNote: (state, { payload }) => {
      const currentNotes = cloneDeep(state.companyNotes.data);
      state.companyNotes.data = [...payload, ...currentNotes];
    },
    onFailedCompanyNotes: (state) => {
      state.companyNotes.isFetched = true;
      state.companyNotes.data = [];
      state.companyNotes.columnNames = [];
      state.companyNotes.isLoading = false;
    },

    onCallOrgReps: (state, { payload }) => {
      if (!payload) {
        state.orgRep.isFetched = false;
      }
      state.orgRep.columnNames = [];
      state.orgRep.data = [];
      state.orgRep.isLoading = true;
    },
    onSuccessOrgReps: (state, { payload }) => {
      state.orgRep.isFetched = true;
      state.orgRep.data = payload.tableData;
      state.orgRep.columnNames = payload.columnNames;
      state.orgRep.isLoading = false;
    },
    onFailedOrgReps: (state) => {
      state.orgRep.isFetched = true;
      state.orgRep.data = [];
      state.orgRep.columnNames = [];
      state.orgRep.isLoading = false;
    },

    onCallCompanyTasks: (state, payload) => {
      if (!payload) {
        state.companyTasks.isFetched = false;
      }
      state.companyTasks.columnNames = [];
      state.companyTasks.data = [];
      state.companyTasks.isLoading = true;
    },
    onSuccessCompanyTasks: (state, { payload }) => {
      state.companyTasks.isFetched = true;
      state.companyTasks.data = payload.tableData;
      state.companyTasks.isLoading = false;
      state.companyTasks.columnNames = payload.columnNames;
    },
    onFailedCompanyTasks: (state) => {
      state.companyTasks.isFetched = true;
      state.companyTasks.data = [];
      state.companyTasks.isLoading = false;
      state.companyTasks.columnNames = [];
    },

    onSuccessCompanyAdd: (state, { payload }) => {
      if (payload?.companyData) {
        state.companies = [...state.companies, payload?.companyData];
        state.count += 1;
      }
    },
    onSuccessCompanyEdit: (state, { payload }) => {
      if (payload) {
        state.companies = state.companies.map((company) =>
          company.company_id === payload.company_id ? payload : company
        );
      }
    },
    onSuccessCompaniesDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.companies = state.companies.filter((company) => !payload.includes(company.company_id));
        state.count -= 1;
      }
    },

    onCallCompanyAttributes: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onSuccessCompanyAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;

      if (payload.type === "edit") {
        const currentList = cloneDeep(state[payload.key].list);
        if (!currentList.length) {
          state[payload.key].isFetched = false;
        }
        state[payload.key].list = currentList.map((item) => {
          if (payload.data[payload.idKey] === item.code) {
            return {
              code: payload.data[payload.idKey],
              name: payload.data.Group,
              isDefault: payload.data.Default
            };
          }
          return item;
        });
      }

      if (payload.type === "add") {
        const currentList = cloneDeep(state[payload.key].list);
        state[payload.key].list = [
          ...currentList,
          {
            code: payload.data[payload.idKey],
            name: payload.data.Group,
            isDefault: payload.data.Default
          }
        ];
      }

      if (payload.key === "orgRep") {
        state[payload.key].list = payload.data.tableData.map((item) => ({
          code: item.tenant_user_id,
          name: `${item["First Name"]} ${item["Last Name"]}`,
          isDefault: item.default_value ?? false
        }));
        return;
      }

      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );

        state[payload.key].list = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.default_value
        }));
      }
    },
    onFailedCompanyAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },

    onSuccessCompanyAttributeAdd: (state, { payload }) => {
      const codeFieldName = Object.keys(payload.data).find((k) => k.includes("_id"));
      const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));
      const currentList = cloneDeep(state[payload.key].list);
      state[payload.key].list = [
        ...currentList,
        {
          code: payload.data[codeFieldName],
          name: payload.data[nameFieldName],
          isDefault: payload.data.Default
        }
      ];
    },

    // attribute loading
    switchCompaniesAttributeChangeLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },
    onSuccessCompanyAttributeChange: (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        state.companies = state.companies.map(
          (c) => payload.data.find((newC) => newC.company_id === c.company_id) || c
        );
      }
    },

    onCallCompanySocialMedias: (state) => {
      state.socialMedias.isFetched = false;
      state.socialMedias.list = [];
    },
    onSuccessCompanySocialMedias: (state, { payload }) => {
      state.socialMedias.isFetched = true;

      if (payload?.social_media_items) {
        state.socialMedias.list = payload?.social_media_items
          .filter((item) => item.visible)
          .map((item) => ({
            social_media_id: item.social_media_id,
            name: item.social_media
          }));
      }
    },
    onFailedCompanySocialMedias: (state) => {
      state.socialMedias.isFetched = true;
      state.socialMedias.list = [];
    },

    onCallCompanyCustomFields: (state) => {
      state.customField.isFetched = false;
      state.customField.list = [];
    },
    onSuccessCompanyCustomFields: (state, { payload }) => {
      state.customField.isFetched = true;

      if (payload?.custom_fields) {
        state.customField.list = payload.custom_fields
          .filter((item) => item.visible && !Number.isInteger(item.custom_field_definition_parent_id))
          .map((item) => {
            if (item.list) {
              const children = item.list_items.map((c) => ({
                ...c,
                default_value: payload.custom_fields.find(
                  (i) => i.custom_field_definition_id === c.custom_field_definition_id
                )?.default_value
              }));
              return {
                ...item,
                list_items: children
              };
            }

            return item;
          });
      }
    },
    onReorderMainCompanyCustomFields: (state, { payload }) => {
      if (state.customField.list.length > 0) {
        state.customField.list = state.customField?.list.map((item) => {
          const findCustomField = payload?.data?.find(
            (cf) => cf?.custom_field_definition_id === item?.custom_field_definition_id
          );
          if (findCustomField) {
            return {
              ...item,
              position: findCustomField.Position
            };
          }
          return item;
        });
        state.customField.list = [...state.customField.list].sort((a, b) => (a.position > b.position ? 1 : -1));
      }
    },
    onFailedCompanyCustomFields: (state) => {
      state.customField.isFetched = true;
      state.customField.list = [];
    },

    onCallActiveTenantUsers: (state) => {
      state.activeTenantUsers.isFetched = false;
      state.activeTenantUsers.list = [];
    },
    onSuccessActiveTenantUsers: (state, { payload }) => {
      state.activeTenantUsers.isFetched = true;
      state.activeTenantUsers.list = payload?.tenant_users.map((user) => ({
        name: `${user.first_name} ${user.last_name}`,
        code: user.tenant_user_id
      }));
    },
    onFailedActiveTenantUsers: (state) => {
      state.activeTenantUsers.isFetched = true;
      state.activeTenantUsers.list = [];
    },
    onCompanySave: (state, { payload }) => {
      state.companyDraft = payload;
    },
    resetCompanyDraft: (state) => {
      state.companyDraft = {};
    },

    onCallCompanyDocuments: (state) => {
      state.companyDocuments.isFetched = false;
      state.companyDocuments.columnNames = [];
      state.companyDocuments.data = [];
    },
    onSuccessCompanyDocuments: (state, { payload }) => {
      state.companyDocuments.isFetched = true;
      if (payload && payload?.document_list && payload?.columnNames) {
        state.companyDocuments.data = payload.document_list;
        state.companyDocuments.columnNames = payload.columnNames.filter((cN) => cN.column_name !== "document_id");
      }
    },
    onFailedCompanyDocuments: (state) => {
      state.companyDocuments.isFetched = true;
      state.companyDocuments.data = [];
      state.companyDocuments.columnNames = [];
    }
  }
});

export const {
  onCallCompanies,
  onSuccessCompanies,
  onFailedCompanies,

  onAddNewNote,

  switchCompanyLoading,
  onReorderMainCompanyCustomFields,

  onSuccessCompany,
  onFailedCompany,
  resetCompany,

  onCallCompanyContacts,
  onSuccessCompanyContacts,
  onFailedCompanyContacts,

  onCallCompanyNotes,
  onSuccessCompanyNotes,
  onFailedCompanyNotes,

  onCallCompanyTasks,
  onSuccessCompanyTasks,
  onFailedCompanyTasks,

  onSuccessCompanyAdd,
  onSuccessCompanyEdit,
  onSuccessCompaniesDelete,

  onCallCompanyAttributes,
  onSuccessCompanyAttributes,
  onFailedCompanyAttributes,
  onSuccessCompanyAttributeAdd,
  switchCompaniesAttributeChangeLoading,
  onSuccessCompanyAttributeChange,

  onCallCompanySocialMedias,
  onSuccessCompanySocialMedias,
  onFailedCompanySocialMedias,

  onCallCompanyCustomFields,
  onSuccessCompanyCustomFields,
  onFailedCompanyCustomFields,

  onCallActiveTenantUsers,
  onSuccessActiveTenantUsers,
  onFailedActiveTenantUsers,

  onCompanySave,
  resetCompanyDraft,

  onCallCompanyDocuments,
  onSuccessCompanyDocuments,
  onFailedCompanyDocuments
} = companiesSlice.actions;

export default companiesSlice.reducer;
