import { createPromiseAction } from "@adobe/redux-saga-promise";

export const COMPANIES_CALL = "COMPANIES_CALL";
export const COMPANIES_CALL_PROMISE = "COMPANIES_CALL_PROMISE";
export const COMPANY_CALL = "COMPANY_CALL";
export const COMPANY_ADD_CALL = "COMPANY_ADD_CALL";
export const COMPANY_ADD_CALL_PROMISE = "COMPANY_ADD_CALL_PROMISE";
export const COMPANY_EDIT_CALL = "COMPANY_EDIT_CALL";
export const COMPANY_EDIT_CALL_PROMISE = "COMPANY_EDIT_CALL_PROMISE";
export const COMPANIES_DELETE_CALL = "COMPANIES_DELETE_CALL";
export const COMPANIES_RESTORE_CALL = "COMPANIES_RESTORE_CALL";

export const COMPANY_CONTACTS_CALL = "COMPANY_CONTACTS_CALL";

export const COMPANY_DOCUMENTS_CALL = "COMPANY_DOCUMENTS_CALL";

export const COMPANY_NOTES_CALL = "COMPANY_NOTES_CALL";

export const COMPANY_TASKS_CALL = "COMPANY_TASKS_CALL";

// export const COMPANY_TYPE_ADDRESS_CALL = 'COMPANY_TYPE_ADDRESS_CALL';

export const COMPANIES_CALL_SALES_TAXES = "COMPANIES_CALL_SALES_TAXES";
export const COMPANIES_CALL_STATUSES = "COMPANIES_CALL_STATUSES";
export const COMPANIES_CALL_TYPES = "COMPANIES_CALL_TYPES";
export const COMPANIES_CALL_CLASSIFICATIONS = "COMPANIES_CALL_CLASSIFICATIONS";
export const COMPANIES_CALL_ORGREPS = "COMPANIES_CALL_ORGREPS";
export const COMPANIES_CALL_GROUPS = "COMPANIES_CALL_GROUPS";
export const COMPANIES_CALL_CUSTOM_FIELDS = "COMPANIES_CALL_CUSTOM_FIELDS";
export const COMPANIES_CALL_SOCIAL_MEDIAS = "COMPANIES_CALL_SOCIAL_MEDIAS";
export const COMPANIES_CALL_ACTIVE_TENANT_USERS = "COMPANIES_CALL_ACTIVE_TENANT_USERS";

export const COMPANIES_CHANGE_STATUSES = "COMPANIES_CHANGE_STATUSES";
export const COMPANIES_CHANGE_TYPES = "COMPANIES_CHANGE_TYPES";
export const COMPANIES_CHANGE_CLASSIFICATIONS = "COMPANIES_CHANGE_CLASSIFICATIONS";
export const COMPANIES_CHANGE_ORG_REP = "COMPANIES_CHANGE_ORG_REP";
export const COMPANIES_CHANGE_GROUPS = "COMPANIES_CHANGE_GROUPS";

export const callCompaniesPromise = createPromiseAction(COMPANIES_CALL_PROMISE);
export const callCompanyAddPromise = createPromiseAction(COMPANY_ADD_CALL_PROMISE);
export const callSingleCompanyAddPromise = createPromiseAction(COMPANY_ADD_CALL);
export const callCompanyEditPromise = createPromiseAction(COMPANY_EDIT_CALL_PROMISE);

export function callCompanies(paginationData, tenantUserId) {
  return { type: COMPANIES_CALL, payload: { paginationData, tenantUserId } };
}

export function callCompany(companyId, resolve, reject) {
  return { type: COMPANY_CALL, payload: { companyId, resolve, reject } };
}

export function callDeleteCompanies(companyIds) {
  return { type: COMPANIES_DELETE_CALL, payload: { companyIds } };
}

// Address
// export function callCompanyAddressByType(companyId, type = 'default_main' /* default_main, shipping_to, billing_to */) {
//     return { type: COMPANY_TYPE_ADDRESS_CALL, payload: { companyId, type } };
// }
export function callCompanyContacts(companyId) {
  return { type: COMPANY_CONTACTS_CALL, payload: { companyId } };
}

export function callCompanyNotes(entityId, module, isSocketUpdate) {
  return { type: COMPANY_NOTES_CALL, payload: { entityId, module, isSocketUpdate } };
}

export function callCompanyTasks(companyId, isSocketUpdate) {
  return { type: COMPANY_TASKS_CALL, payload: { companyId, isSocketUpdate } };
}

export function callCompanyAttributes(type, data) {
  return { type, payload: { tenant_user_id: data } };
}

export function callCompaniesChangeAttributes(type, data) {
  return { type, payload: { data } };
}

export function callCompanyDocuments(companyId) {
  return { type: COMPANY_DOCUMENTS_CALL, payload: { companyId } };
}
