import { createPromiseAction } from "@adobe/redux-saga-promise";

export const DASHBOARDS_CALL = "DASHBOARDS_CALL";
export const DASHBOARD_ADD_CALL = "DASHBOARD_ADD_CALL";
export const DASHBOARD_EDIT_CALL = "DASHBOARD_EDIT_CALL";
export const DASHBOARD_DELETE_CALL = "DASHBOARD_DELETE_CALL";
export const DASHBOARD_RESTORE_CALL = "DASHBOARD_RESTORE_CALL";
export const DASHBOARD_LAYOUT_EDIT_CALL = "DASHBOARD_LAYOUT_EDIT_CALL";
export const DASHBOARD_ITEM_ADD_CALL_PROMISE = "DASHBOARD_ITEM_ADD_CALL_PROMISE";
export const DASHBOARD_ITEM_EDIT_CALL_PROMISE = "DASHBOARD_ITEM_EDIT_CALL_PROMISE";
export const DASHBOARD_ITEM_DELETE_CALL_PROMISE = "DASHBOARD_ITEM_DELETE_CALL_PROMISE";
export const DASHBOARD_ITEM_DEFINITIONS_CALL = "DASHBOARD_ITEM_DEFINITIONS_CALL";
export const DASHBOARD_ITEM_DETAILS_CALL = "DASHBOARD_ITEM_DETAILS_CALL";
export const DASHBOARD_ITEM_DETAILS_CALL_PROMISE = "DASHBOARD_ITEM_DETAILS_CALL_PROMISE";


export const GET_SINGLE_DASHBOARD_DATA = "GET_SINGLE_DASHBOARD_DATA";

export function callDashboards() {
  return { type: DASHBOARDS_CALL };
}

export function getSingleDashboardData(dashboardId) {
  return { type: GET_SINGLE_DASHBOARD_DATA, payload: { dashboardId } };
}

export function callAddDashboard(dashboardData, messageFunction) {
  return { type: DASHBOARD_ADD_CALL, payload: { dashboardData, messageFunction } };
}

export function callEditDashboard(dashboardId, dashboardData, messageFunction) {
  return { type: DASHBOARD_EDIT_CALL, payload: { dashboardId, dashboardData, messageFunction } };
}

export function callDeleteDashboards(dashboardIds) {
  return { type: DASHBOARD_DELETE_CALL, payload: { dashboardIds } };
}

export function callEditDashboardLayout(dashboardId, layout, messageFunction) {
  return { type: DASHBOARD_LAYOUT_EDIT_CALL, payload: { dashboardId, layout, messageFunction } };
}

export function callDashboardItemDefinitions() {
  return { type: DASHBOARD_ITEM_DEFINITIONS_CALL };
}

export function callDashboardItemDetails(itemId, definitionId, paginationData) {
  return { type: DASHBOARD_ITEM_DETAILS_CALL, payload: { itemId, definitionId, paginationData } };
}

// Dashboard items
export const callDashboardItemAddPromise = createPromiseAction(DASHBOARD_ITEM_ADD_CALL_PROMISE);
export const callDashboardItemEditPromise = createPromiseAction(DASHBOARD_ITEM_EDIT_CALL_PROMISE);
export const callDashboardItemDeletePromise = createPromiseAction(DASHBOARD_ITEM_DELETE_CALL_PROMISE);
export const callDashboardItemDetailsPromise = createPromiseAction(DASHBOARD_ITEM_DETAILS_CALL_PROMISE);
