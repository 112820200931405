import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: {
    isFetched: false,
    isLoading: false,
    isLayoutLoading: false,
    dashboards: [],

    dashboardItemDefinitions: {
      isFetched: false,
      data: []
    },

    activeDashboardId: null,
    timeSyncId: uuidv4(),

    dashboardItemDetails: {
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      },
      count: 0,
      isFetched: false,
      columnNames: [],
      list: []
    },
    singleDashboardData: {
      isFetched: false,
      isFetching: false,
      data: {},
      error: null
    },
  },
  reducers: {
    onCallGetSingleDashboard: (state) => {
      state.singleDashboardData.isFetching = true;
    },
    onCallGetSingleDashboardSuccess: (state, { payload }) => {
      state.singleDashboardData.isFetching = false;
      state.singleDashboardData.isFetched = true;
      state.singleDashboardData.data = payload;
    },
    onCallGetSingleDashboardFailure: (state, { payload }) => {
      state.singleDashboardData.isFetching = false;
      state.singleDashboardData.isFetched = true;
      state.singleDashboardData.error = payload;
    },
    onCallDashboards: (state) => {
      state.isFetched = false;
      state.dashboards = [];
    },
    onSuccessDashboards: (state, { payload }) => {
      const { dashboards } = payload;
      if (dashboards) {
        state.isFetched = true;
        state.dashboards = dashboards;
      }
    },
    onFailedDashboards: (state) => {
      state.isFetched = true;
      state.dashboards = [];
    },

    // single dashboard
    switchDashboardLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    switchDashboardLayoutLoading: (state, { payload }) => {
      state.isLayoutLoading = payload !== undefined ? payload : !state.isLayoutLoading;
    },

    onSuccessDashboardAdd: (state, { payload }) => {
      if (payload?.dashboard) {
        const { dashboard_id, name, shared, tenant_id, tenant_user_id } = payload.dashboard;

        state.dashboards = [
          {
            dashboard_id,
            name,
            shared,
            tenant_id,
            tenant_user_id
          },
          ...state.dashboards
        ];
      }
    },
    onSuccessDashboardEdit: (state, { payload }) => {
      if (payload?.dashboard) {
        const { dashboard_id, name, shared } = payload.dashboard;
        // state.dashboard.data.name = name;
        // state.dashboard.data.shared = shared;
        state.dashboards = state.dashboards.map((dashboard) =>
          dashboard.dashboard_id === dashboard_id
            ? {
              ...dashboard,
              name,
              shared
            }
            : dashboard
        );
      }
    },
    onSuccessDashboardsDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.dashboards = state.dashboards.filter((dashboard) => !payload.includes(dashboard.dashboard_id));
      }
    },

    // dashboard item definitions
    onCallDashboardItemDefinitions: (state) => {
      state.dashboardItemDefinitions.isFetched = false;
      state.dashboardItemDefinitions.data = [];
    },
    onSuccessDashboardItemDefinitions: (state, { payload }) => {
      state.dashboardItemDefinitions.isFetched = true;
      state.dashboardItemDefinitions.data = payload.dashboardItemDefinitionList;
    },
    onFailedDashboardItemDefinitions: (state) => {
      state.dashboardItemDefinitions.isFetched = true;
      state.dashboardItemDefinitions.data = [];
    },

    setActiveDashboardId: (state, { payload }) => {
      state.activeDashboardId = payload;
    },

    updateDashboardTimeSyncId: (state) => {
      state.timeSyncId = uuidv4();
    },

    onCallDashboardItemDetails: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.dashboardItemDetails.isFetched = false;
        state.dashboardItemDetails.columnNames = [];
        state.dashboardItemDetails.count = 0;
      } else {
        // with pagination
        state.dashboardItemDetails.pagination = payload;
      }
      state.dashboardItemDetails.list = [];
    },
    onSuccessDashboardItemDetails: (state, { payload }) => {
      state.dashboardItemDetails.list = payload.tableData;
      state.dashboardItemDetails.columnNames = payload.columnNames;
      state.dashboardItemDetails.count = payload.total_count;
      state.dashboardItemDetails.isFetched = true;
    },
    onFailedDashboardItemDetails: (state) => {
      state.dashboardItemDetails.list = [];
      state.dashboardItemDetails.columnNames = [];
      state.dashboardItemDetails.count = 0;
      state.dashboardItemDetails.isFetched = true;
    },
    clearDashboardItemDetails: (state) => {
      state.dashboardItemDetails.list = [];
      state.dashboardItemDetails.columnNames = [];
      state.dashboardItemDetails.count = 0;
      state.dashboardItemDetails.isFetched = false;
    }
  }
});

export const {
  onCallGetSingleDashboard,
  onCallGetSingleDashboardSuccess,
  onCallGetSingleDashboardFailure,


  onCallDashboards,
  onSuccessDashboards,
  onFailedDashboards,
  switchDashboardLoading,
  switchDashboardLayoutLoading,

  onSuccessDashboardAdd,
  onSuccessDashboardEdit,
  onSuccessDashboardsDelete,

  onCallDashboardItemDefinitions,
  onSuccessDashboardItemDefinitions,
  onFailedDashboardItemDefinitions,

  setActiveDashboardId,
  updateDashboardTimeSyncId,

  onCallDashboardItemDetails,
  onSuccessDashboardItemDetails,
  onFailedDashboardItemDetails,
  clearDashboardItemDetails
} = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
