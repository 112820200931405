import React, { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUserTheme } from "hooks";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import GlobalStyles from "styles/global";
import LazyLoad from 'components/common/lazy-load';
import { thirdPartAuthRouteNames } from "constant/routes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs";
import ThirdPartyLayout from "layouts/third-party";
import { generateTabId, updateHistory } from "store/user/sagas-actions";
import { SocketProvider } from "./context/socketProvider";
import { ErrorBoundary } from "react-error-boundary";
import VideoPlayer from './pages/Video/VideoPlayer';


import "assets/styles/styles.scss";
import { axiosRequest } from "./clients/api";
import { isProduction } from "./constant/env-variables";
// import 'assets/fonts/fonts.css';

const AppAuthLayer = lazy(() => import("layouts/app-auth-layer"));
const DocumentLayout = lazy(() => import("layouts/document"));
// const ThirdPartyLayout = lazy(() => import('layouts/third-party'));
const pdfPreloader = lazy(() => import("layouts/pdfPreloader"));
const Error = lazy(() => import("./pages/app/Error"));
const App = () => {
  const userTheme = useUserTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        [
          "ResizeObserver loop limit exceeded",
          "ResizeObserver loop completed with undelivered notifications."
        ].includes(e.message)
      ) {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    const tabId = sessionStorage.getItem("tabId");
    if (!tabId) {
      dispatch(generateTabId());
    }
  }, [dispatch]);

  const errorFallbackRenderer = ({ error, resetErrorBoundary }) => {
    if (isProduction) {
      if (error && error.message) {
        axiosRequest('post', 'tenantUser/app-log', { type_id: 4, message: error.message });
      }

      const pathName = window.location.pathname;
      const iframeToRemove = document.querySelector('#webpack-dev-server-client-overlay');

      if (iframeToRemove) {
        iframeToRemove.remove();
      }
      resetErrorBoundary();
      return <Redirect to={{ pathname: '/error', state: { pathName } }} />;
    }
  };

  useEffect(() => {
    if (
      history
      && history.location
      && history.location.search
      && (history.location.search.includes('impToken') || history.location.search.includes('sso_token'))
    ) {
      const decodedToken = decodeURIComponent(history.location.search).split('=')[1];
      localStorage.setItem('userToken', decodedToken);

      if (history.location.search.includes('impToken')) {
        localStorage.setItem('isImp', true);
      }
    }
  }, [history]);


  useEffect(() => {
    history.listen((route) => {
      if (
        route.pathname !== '/app/contacts' &&
        route.pathname !== '/app/companies' &&
        route.pathname !== '/app/products-services' &&
        route.pathname !== '/app/opportunities' &&
        route.pathname !== '/app/documents' &&
        route.pathname !== '/app/dashboard' &&
        route.pathname !== '/app/dashboard/fullscreen' &&
        route.pathname !== '/app' &&
        route.pathname !== '/app/contacts/new' &&
        route.pathname !== '/app/companies/new' &&
        route.pathname !== '/app/documents/new' &&
        route.pathname !== '/app/products-services/new' &&
        !route.pathname.includes("/app/organization")
      ) {
        dispatch(updateHistory(route));
      }
    });
  }, [history]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={userTheme}>
          <SocketProvider>
            <CssBaseline />
            <GlobalStyles />
            <LazyLoad>
              <ErrorBoundary fallbackRender={errorFallbackRenderer}>
                <Switch>
                  <Route path="/error" component={Error} />
                  <Route exact path="/video" component={VideoPlayer} />
                  <Route path="/pdf/preview/:documentId" component={pdfPreloader} />
                  <Route path="/document/:documentId" component={DocumentLayout} />
                  <Route path={`(${Object.values(thirdPartAuthRouteNames).join("|")})`} component={ThirdPartyLayout} />
                  <Route path="/" component={AppAuthLayer} />
                </Switch>
              </ErrorBoundary>
            </LazyLoad>
          </SocketProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export default App;
