import React, { memo, forwardRef, useState, useCallback } from "react";
import { Controller } from "react-hook-form-v7";
import useDidUpdate from "hooks/common/useDidUpdate";
import usePrevious from "hooks/common/usePrevious";
import usePermission from "components/complex/permission/hook";
import DialogWrapper from "components/complex/dialog-wrapper";
import { TextField, MenuItem, Divider } from "@mui/material";
import SettingCreatEdit from "components/complex/settings/setting-create-edit";
import useStyles from "../style";
import useDeviceType from "../../../../hooks/common/useDeviceType";

const SelectWithAddBody = forwardRef(
  (
    {
      label,
      error,
      options,
      customOnChange,
      disabled,
      addPermission,
      setAddNew,
      allowToAddItem = true,
      setStartAddingNewItem,
      ...otherProps
    },
    ref
  ) => {
    const { isMobile } = useDeviceType();
    const classes = useStyles({ isMobile });
    return (
      <TextField
        inputRef={ref}
        select
        fullWidth
        label={label}
        size="small"
        variant="outlined"
        {...(customOnChange && {
          onChange: (e) => customOnChange(e.target.value),
          value: ""
        })}
        error={Boolean(error)}
        helperText={<span>{error || ""}</span>}
        FormHelperTextProps={{
          className: classes.errorText
        }}
        className={classes.field}
        inputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
            shrink: classes.labelShrink
          }
        }}
        disabled={disabled}
        {...otherProps}>
        {addPermission && allowToAddItem && (
          <MenuItem
            onClick={() => {
              setAddNew(true);
              setStartAddingNewItem(true);
            }}
            {...(!customOnChange && { value: "" })}>
            + Add New {label}
          </MenuItem>
        )}
        {addPermission && allowToAddItem && <Divider />}
        {options &&
          options.map((option, i) =>
            option.code === "divider" ? (
              <Divider key={option.code + i} />
            ) : (
              <MenuItem key={option.code || option} value={option.code !== undefined ? option.code : option}>
                {option.name || option}
              </MenuItem>
            )
          )}
      </TextField>
    );
  }
);

const SelectWithAdd = memo(
  ({
    control,
    CreateDialog,
    setValue,
    error,
    moduleName,
    name,
    label,
    options,
    onChange,
    dialogProps,
    disabled,
    ...otherProps
  }) => {
    const [addNew, setAddNew] = useState();
    const [startAddingNewItem, setStartAddingNewItem] = useState(false);
    const addPermission = usePermission(moduleName, "Add");
    const prevOptionsLength = usePrevious(options.length);

    const onItemCreate = (itemData) => {
      // Set current key in form
      setValue(name, itemData.code, {
        shouldDirty: true,
        shouldValidate: true
      });
    };

    useDidUpdate(() => {
      if (options.length > prevOptionsLength) {
        const itemCode = options[options.length - 1].code;
        if (control) {
          if (name && startAddingNewItem) {
            setValue(name, itemCode, {
              shouldDirty: true,
              shouldValidate: true
            });
          }
        }
        // else {
        //     onChange(itemCode);
        // }
      }
      setStartAddingNewItem(false);
    }, [options]);

    return (
      <>
        {name ? (
          <Controller
            control={control}
            name={name}
            {...(!onChange && { defaultValue: "" })}
            render={({ field }) => (
              <SelectWithAddBody
                {...field}
                label={label}
                error={error}
                options={options}
                setStartAddingNewItem={setStartAddingNewItem}
                customOnChange={onChange}
                disabled={disabled}
                addPermission={addPermission}
                setAddNew={setAddNew}
                {...otherProps}
              />
            )}
          />
        ) : (
          <SelectWithAddBody
            label={label}
            error={error}
            setStartAddingNewItem={setStartAddingNewItem}
            options={options}
            customOnChange={onChange}
            disabled={disabled}
            addPermission={addPermission}
            setAddNew={setAddNew}
            {...otherProps}
          />
        )}

        {addPermission && CreateDialog ? (
          <CreateDialog
            dialogProps={dialogProps}
            isOpen={addNew}
            closePopup={() => setAddNew(false)}
            onItemCreate={onItemCreate}
          />
        ) : (
          <DialogWrapper isOpen={addNew}>
            <SettingCreatEdit
              isNew
              closePopup={() => {
                setAddNew(false);
                setStartAddingNewItem(false);
              }}
              apiKey={dialogProps.apiKey}
              nameKey={label}
              dataSelector={dialogProps.dataSelector}
              addActionName={dialogProps.addActionName}
              addAction={dialogProps.addAction}
              source={"formField"}
            />
          </DialogWrapper>
        )}
      </>
    );
  }
);

export default SelectWithAdd;
